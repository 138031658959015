






















import {
  computed,
  defineComponent,
  getCurrentInstance,
  ref,
  PropType,
} from '@vue/composition-api'

import ExportImportButton from '@/components/molecules/h/ExportImportButton.vue'
import OutputCondition from '@/components/organisms/h/page/jyuyou-yosoku/causal-name/OutputCondition.vue'
import TitleAndManipulate from '@/components/organisms/h/TitleAndManipulate.vue'
import {
  outputCondUseInfoList,
  CausalNameOutputCondition,
} from '@/types/entities'

export default defineComponent({
  components: {
    TitleAndManipulate,
    OutputCondition,
    ExportImportButton,
  },
  props: {
    outputUseList: {
      type: Array as PropType<outputCondUseInfoList>,
      default: () => [],
    },
  },
  setup(_, context) {
    const instance = getCurrentInstance()
    if (!instance) {
      // should be called in setup
      return
    }
    const { $i18n } = instance.proxy

    const requestExport = () => {
      context.emit('request-export')
    }
    const requestImport = () => {
      context.emit('request-import')
    }
    const changeOutputCondition = (cond: CausalNameOutputCondition) => {
      context.emit('change-output-condition', cond)
    }

    return {
      requestExport,
      requestImport,
      changeOutputCondition,
    }
  },
})
